exports.components = {
  "component---src-connected-article-template-connected-js": () => import("./../../../src/connected/ArticleTemplateConnected.js" /* webpackChunkName: "component---src-connected-article-template-connected-js" */),
  "component---src-connected-project-template-connected-js": () => import("./../../../src/connected/ProjectTemplateConnected.js" /* webpackChunkName: "component---src-connected-project-template-connected-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */)
}

